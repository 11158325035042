import React, { createContext, useReducer } from "react";

const AuthContext = createContext();
const { Provider } = AuthContext;

const initialState = {
  isLoggedin: false,
  loginPage: false,
};

const authReducer = (state = initialState, action) => {
  switch (action?.type) {
    case "LOGIN":
      return {
        ...state,
        isLoggedin: action?.payload?.isLoggedin
          ? action?.payload?.isLoggedin
          : initialState?.isLoggedin,
      };
    case "LOGINPAGE":
      return {
        ...state,
        loginPage: action?.payload?.loginPage
          ? action?.payload?.loginPage
          : initialState?.loginPage,
      };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  var [state, dispatch] = useReducer(authReducer, initialState);
  const isLoggedin = localStorage.getItem("systemica-loggedin");
  if (isLoggedin === "true") {
    state = { ...state, isLoggedin: true };
  }

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { AuthProvider, AuthContext };
