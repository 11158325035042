import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export function PrivateRoute() {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("systemica-loggedin");

  if (isLoggedIn !== "true") {
    return <Navigate to='/' state={{ from: location }} />;
  }
  return <Outlet />;
}
