import { Navigate, Route, Routes } from "react-router-dom";
import "./custom.css";
import React, { Suspense } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "./context/auth.context";
import { PrivateRoute } from "./components/privateRoute";
import { FormProvider } from "./context/froms.context";

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#005859",
    },
    primary_lite: {
      main: "#1E9091",
    },
    whiteBtn: {
      main: "#FFFFFF",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

const LoginPage = React.lazy(() => import("./views/home"));
const FormsMain = React.lazy(() => import("./views/forms"));
const ApproveReject = React.lazy(() => import("./views/forms/approve_reject"));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={loading}>
        <Toaster position='top-right' />
        <AuthProvider>
          <FormProvider>
            <Routes>
              <Route exact path='/' element={<Navigate to='/login' />} />
              <Route exact path='/login' name='Login' element={<LoginPage />} />
              <Route
                exact
                path='/form/:flag/:id'
                name='Approve'
                element={<ApproveReject />}
              />
              <Route element={<PrivateRoute />}>
                <Route
                  exact
                  path='/forms'
                  name='forms'
                  element={<FormsMain />}
                />
              </Route>
            </Routes>
          </FormProvider>
        </AuthProvider>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
